<template>
    <div class="sms_user_list">
        <el-card>
            <el-page-header :content="$route.query.name" style="margin-bottom: 30px;" @back="goBack" />
            <nav class="input" style="width: 260px;">
                <el-input v-model="search.keyword" size="medium" placeholder="输入姓名，手机号，公司，职位，备注" />
            </nav>
            <nav class="input" style="width: auto;">
                <el-select v-model="search.waId" placeholder="小助手" size="medium">
                    <el-option
                        v-for="item in $store.state.NormalList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id + ''"
                    />
                </el-select>
            </nav>
            <nav class="input" style="width: auto;">
                <region :val1.sync="search.province" :val2.sync="search.city"></region>
            </nav>
            <nav class="input" style="width: auto;">
                <typeBusinessSubBusiness :form.sync="search"></typeBusinessSubBusiness>
            </nav>
            <nav class="input" style="width: 150px">
                <el-select v-model="search.jobLevel" placeholder="职级" size="medium">
                    <el-option label="全部" :value="-1" />
                    <el-option
                        v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel"
                        :key="index"
                        :label="item"
                        :value="index"
                    />
                </el-select>
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "添加收件人" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="onDelete">{{ "批量删除" }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table
                    @selection-change="handleSelectionChange"
                    :data="list"
                    style="width: 100%"
                    border
                    stripe
                    :header-cell-style="rowClass"
                >
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="realName" label="姓名"> </el-table-column>
                    <!-- <el-table-column prop="phone" label="手机"> </el-table-column> -->
                    <el-table-column prop="company" label="公司"> </el-table-column>
                    <el-table-column prop="job" label="职位"> </el-table-column>
                    <el-table-column prop="jobLevelName" label="职级"> </el-table-column>
                    <el-table-column prop="jobLevelName" label="省市">
                        <template #default="{row}"> {{ row.provinceName }} - {{ row.cityName }} </template>
                    </el-table-column>
                    <el-table-column prop="typeName" label="类型"> </el-table-column>
                    <el-table-column prop="businessName" label="行业"> </el-table-column>
                    <el-table-column prop="subBusinessName" label="子行业"> </el-table-column>
                    <el-table-column prop="remark" label="备注"> </el-table-column>
                    <el-table-column prop="assistant" label="小助手"> </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <!-- <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button
                                @click="management(row)"
                                style="color: sandybrown;"
                                type="text"
                                icon="el-icon-s-help"
                                size="mini"
                                >管理收件人</el-button
                            >

                            <el-button @click="editor(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                size="mini"
                                style="color: red;"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column> -->
                </el-table>
            </z-table>
        </el-card>
        <pop ref="pop" :title="edit ? '修改收件人标签' : '新增收件人'" @toAudit="toAudit">
            <el-form :model="form" ref="form" label-width="80px" :inline="false" size="normal">
                <el-form-item label="姓名">
                    <el-input v-model="form.realName"></el-input>
                </el-form-item>
                <el-form-item label="手机">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="公司">
                    <el-input v-model="form.company"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="form.job"></el-input>
                </el-form-item>
            </el-form>
        </pop>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import typeBusinessSubBusiness from "@/components/type-business-subBusiness/index";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
                privince: "",
                city: "",
                waId: "",
                sutId: "",
                type: "",
                business: "",
                subBusiness: "",
                jobLevel: "",
            },
            form: {
                realName: "",
                phone: "",
                company: "",
            },
            edit: false,
            title: "加载中...",
            selection: [],
        };
    },
    components: {
        typeBusinessSubBusiness,
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Sms/getSmsUserList", {
                params: { ...val, ...this.search, sutId: this.$route.query.id },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        handleSelectionChange(e) {
            this.selection = e;
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            var arr = this.selection.map((item) => item.id);
            let { data: res } = await this.$http.post("/admin/Sms/delSmsUser", { ids: arr.toString() });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        add() {
            this.form = this.$options.data.call(this).form;
            this.form.tagId = this.$route.query.id;
            this.$refs.pop.show = true;
            this.edit = false;
        },
        async editor(row) {
            this.edit = true;
            let { data: res } = await this.$http.get("/admin/Sms/getTagById", { params: { id: row.id } });
            if (res.errorCode == 200) {
                this.$refs.pop.show = true;
                this.form = res.data;
            }
        },
        async toAudit() {
            let { data: res } = this.edit
                ? await this.$http.post("/admin/Sms/editTag", this.form)
                : await this.$http.post("/admin/Sms/addSmsUser", this.form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
                this.$refs.pop.show = false;
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
    mounted() {
        this.title = this.$route.query.name;
    },
};
</script>

<style lang="scss" scoped></style>
